import React from 'react'
import { Router } from '@reach/router'
// import Join from './joins'
import JoinA from '../components/joina'
import NotFoundPage from './404'
import { graphql } from 'gatsby'

const JoinRoute = props => {
  return (
    <Router basepath="/joina">
      <JoinA path="/*" data={props.data} />
      <NotFoundPage default withLayout={false} />
    </Router>
  )
}

export default JoinRoute

export const query = graphql`
  query {
    allImageSharp(
      filter: {
        fluid: {
          originalName: {
            in: [
              "shop-millions-of-products.png"
              "create-share-link.png"
              "your-shopping-board.png"
              "chirpyest-curated-style-finds.png"
              "inspiration-on-our-blog.png"
              "chirpyest-works-on-mobile.png"
            ]
          }
        }
      }
      sort: { order: ASC, fields: fluid___originalName }
    ) {
      edges {
        node {
          id
          fluid(maxHeight: 1024, quality: 100) {
            src
            srcSet
            base64
            aspectRatio
            originalImg
            originalName
            sizes
          }
        }
      }
    }
  }
`
